<template>
  <div class="index-page">
    <div class="bg">
      <header>
        <div class="logo-wrap">
          <img src="@/assets/logo.png" class="logo" alt="一秒云盘">
          <h1>一秒云盘</h1>
          <a href="https://shimo.im/docs/PqqtjWDHggRtvyvC/" target="_blank">帮助中心</a>
        </div>
        <div class="login-wrap">
          <!-- <a href="javascript:void(0);" v-if="!isLogin" @click="goLogin">登录/注册</a> -->
          <a href="javascript:void(0);" @click="goToApp">个人中心</a>
        </div>
      </header>

      <div class="intro">
        <h1>一秒云盘 - 专业个人云加速服务</h1>
        <h3>我们通过阿里云、腾讯云、百度云、以及各种边缘CDN节点，帮你预加速互联网的公开访问内容</h3>
        <p>*目前服务仅提供给中国大陆地区</p>
      </div>

      <div class="button-wrap">
        <a :href="updateInfo.windows" class="button-item" :class="{'disable': !updateInfo.windows}"  target="_blank">
          <i class="iconfont icon-Windows"></i>Windows下载
        </a>
        <a :href="updateInfo.mac" class="button-item" :class="{'disable': !updateInfo.mac}"  target="_blank">
          <i class="iconfont icon-mac"></i>Mac下载
        </a>
        <a v-if="updateInfo.android" :href="updateInfo.android" class="button-item" target="_blank">
          <i class="iconfont icon-android"></i>Android下载
        </a>
        <a v-if="!updateInfo.android" href="javascript:void(0)" class="button-item disable" @click="showTip">
          <i class="iconfont icon-android"></i>Android下载
        </a>
      </div>
      <!-- <p class="tip">最新版本：{{updateInfo.version}} 更新时间：{{updateInfo.updatetime}}</p> -->
      <p class="tip" v-if="updateInfo.intro">{{updateInfo.intro}}</p>
<!-- 
      <div class="button-single">
        <a class="button-item" href="javascript:void(0);" @click="goToApp">
          进入个人中心
        </a>
      </div> -->
    </div>



    <div class="intro-wrap">
      <img src="@/assets/display.png" class="display" alt="">
      <img src="@/assets/intro_1.png" class="intro_1" alt="">
      <img src="@/assets/intro_2.png" class="intro_2" alt="">
    </div>
    <footer> 
      <p>Copyright © 一秒云盘 Team 2020 Foundation</p>
      <p><a href="http://www.beian.miit.gov.cn/" target="_blank">沪ICP备2020034055号-1</a></p>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    showTip () {
      this.$message.success('正在开发中，敬请期待。')
    },
    goToApp () {
      this.$router.push('/user')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.bg {
  color: #fff;
  height: 400px;
  background-image: url('../assets/bg.png');
  background-size: cover;
}

header {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .logo-wrap {
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 14px;
    margin-right: 10px;
  }
  .logo {
    height: 20px;
    margin-right: 10px;
  }
  a {
    font-size: 13px;
    color: #fff;
    text-decoration: none;
  }
}
.intro {
  padding: 20px;
  margin-top: 20px;
  text-align: center;
    h1 {
      font-weight: normal;
      font-size: 18px;
    }
    h3 {
      font-size: 13px;
    }
    h3 {
      margin-top: 20px;
    }
    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .button-single .button-item {
    width: 90%;
  }
.button-wrap, .button-single {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .button-item {
    padding: 4px 10px;
    border: 1px solid #fff;
    border-radius: 2px;
    margin-right: 10px;
    color: #fff;
    transition: all ease 0.2s;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    i {
      margin-right: 2px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $theme-color;
      background-color: #fff;
    }
    &.disable {
      background-color: #eee;
      color: #666;
    }
  }
}

.tip {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
}

.intro-wrap {
  margin: -100px auto 20px;
  img {
    width: 100%;
  }
  .intro_2, .intro_1 {
    margin-top: 20px;
  }
}

footer {
  color: #fff;
  padding: 20px;
  text-align: center;
  background-image: url('../assets/footer.png');
  background-size: cover;
  background-position: center center;
  text-align: center;
  font-size: 12px;
  a {
    color: inherit;
  }
}
</style>
